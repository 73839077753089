<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-10">
          <h1>Дорогие клиенты!</h1>
          <p class="lead">Мы&nbsp;рады приветствовать вас на&nbsp;нашем сайте программы привилегий.</p>
          <hr>
          <h4>Каталог вознаграждений</h4>
          <p>В&nbsp;каталоге вознаграждений Вы&nbsp;найдете электронные подарочные сертификаты известных брендов. Вы&nbsp;можете выбрать любой сертификат.</p>
          <p>Вы&nbsp;можете выбирать любое количество сертификатов в&nbsp;день без ограничений.</p>
          <p class="red">Обращаем Ваше внимание:<br>
          Подарки стоимостью менее 4&nbsp;000&nbsp;рублей в&nbsp;год налогом не&nbsp;облагаются (п.28. ст. 217 НК РФ).</p>
          <router-link class="button button--rounded button--large" :to="{ name: 'Catalog' }">Выбрать подарок</router-link>
          <br>
          <br>
          <hr>
          <p><b>Мы&nbsp;всегда рады ответить на&nbsp;Ваши вопросы:</b></p>
          <p><b>Телефон службы поддержки:</b><br>
          <a href="tel:88007009090">8&nbsp;(800)&nbsp;700-90-90</a></p>
          <p><b>Электронная почта:</b><br>
          <a href="mailto:support@giftery.ru">support@giftery.ru</a></p>
          <br>
          <br>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
}
</script>

<script>
import 'base/components/Cabinet'

export default {
  name: 'Cabinet',
  mounted: function () {
    this.$store.commit('app/updatePageClass', 'page--cabinet')
  }
}
</script>

<style lang="css" scoped>
  .red {
    color: #c00;
  }

  .buttons {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    width: 60%;
  }

  .buttons a {
    display: flex;
    text-align: center;
    font-size: 20px;
    padding: 1em;
    width: 49%;
    margin-bottom: 15px;
  }

  .buttons a span {
    display: block;
    margin: auto;
    line-height: 1.2;
  }

  @media (max-width: 991px) {
    .buttons {
      flex-wrap: wrap;
      width: auto;
    }

    .buttons a {
      width: 49%;
    }
  }

  @media (max-width: 575px) {
    .buttons {
      display: block;
    }

    .buttons a {
      width: auto;
    }
  }
</style>
